<template>
  <div class="modal" v-if="active">
    <div class="overlay" v-on:click="toggleModal"></div>
    <div class="modal-card" id="modalwindow">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-white has-text-centered">History of {{pieceOfContent["title"]["neutral"]}}</p>
        <button class="delete" aria-label="close" @click="toggleModal"></button>
      </header>
      <section style="font-size: 1rem;" class="modal-card-body has-text-white"><i class="fas fa-calendar-day fa-fw" style="color: #7D98FF"></i> Released in {{pieceOfContent["monthCreated"]}}<br>
        <li v-for="item in getFeaturedContent" :key="item">
          <i v-if="item[0] === 'Extended'" class="fas fa-plus-square fa-fw" style="color: #7D98FF"></i>
          <i v-if="item[0] === 'Delisted'" class="fas fa-calendar-times fa-fw" style="color: #7D98FF"></i>
          <i v-if="item[0] === 'Relisted'" class="fas fa-calendar-day fa-fw" style="color: #7D98FF"></i>
          <i v-if="item[0] === 'Featured'" class="fas fa-star fa-fw" style="color: #7D98FF"></i>
          <i v-if="item[0] === 'PriceUpdate'" class="fas fa-pen fa-fw" style="color: #7D98FF"></i>
          <i v-if="item[0] === 'Removed'" class="fas fa-pen fa-fw" style="color: #7D98FF"></i>
          <i v-if="item[0] === 'RealmsPlusRemoved'" class="fas fa-star fa-fw" style="color: #7D98FF"></i>
          <i v-if="item[0] === 'RealmsPlusAdded'" class="fas fa-star fa-fw" style="color: #7D98FF"></i>
          {{item[1]}}
        </li>
      </section>
    </div>
   </div>
</template>


<script>
export default {
  name: "HistoryModal",
  props: {
    active: Boolean,
    toggleModal: Function,
    pieceOfContent: Object
  },
  computed: {
    getFeaturedContent() {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

      let arToReturn = [];
      for (let i in this.pieceOfContent.events) {
        let eventTitle = Object.keys(this.pieceOfContent.events[i])[0];
        let timestampOfTheFile = eventTitle.split("-")[1];
        let convertSupportDate = new Date(0)
        convertSupportDate.setUTCMilliseconds(timestampOfTheFile)

        if (eventTitle.includes("Relisted")) {
          arToReturn.push(["Relisted", "Content relisted in " + monthNames[convertSupportDate.getMonth()] + " " + convertSupportDate.getFullYear()])
        } else if (eventTitle.includes("Delisted")) {
          arToReturn.push(["Delisted", "Content delisted in " + monthNames[convertSupportDate.getMonth()] + " " + convertSupportDate.getFullYear()])
        } else if (eventTitle.includes("PriceChanged")) {
          arToReturn.push(["PriceUpdate", "Price Updated " + Object.values(this.pieceOfContent.events[i])[0] + " on " + convertSupportDate.toDateString()])
        } else if (eventTitle.includes("Extended")) {
          arToReturn.push(["Extended", "Extended for sale in " + Object.values(this.pieceOfContent.events[i])[0]])
        } else if (eventTitle.includes("Featured")) {
          let tuesdayAfterDate = this.getNextTuesdayAfterDate(convertSupportDate);
          let message = "Featured in " + Object.values(this.pieceOfContent.events[i])[0] + " category on " + tuesdayAfterDate.toDateString();
          let found = false;
          //check for duplicates entries because sometimes more than one Persona Manifest is pushed for a week.
          for(let i = 0; i < Object.values(arToReturn).length; i++){
            if(Object.values(arToReturn)[i][1] === message){
              found = true;
              break;
            }
          }
          if(!found){
            arToReturn.unshift(["Featured", message])
          }
        }else if (eventTitle.includes("RealmsPlusRemoved")){
          arToReturn.push(["RealmsPlusRemoved", "Removed from Realms+ in " +  monthNames[convertSupportDate.getMonth()] + " " + convertSupportDate.getFullYear()])
        }else if (eventTitle.includes("RealmsPlusAdded")){
          arToReturn.push(["RealmsPlusAdded", "Added to Realms+ in " +  monthNames[convertSupportDate.getMonth()] + " " + convertSupportDate.getFullYear()])
        }else if (eventTitle.includes("Removed")){
          arToReturn.push(["Removed", "Removed in " + Object.values(this.pieceOfContent.events[i])[0]])
        }
      }
      return arToReturn
    }
  },
  methods: {
      getNextTuesdayAfterDate(d) {
        let days = 0;
        if (d.getDay() <= 2) {
          days = 2 - d.getDay();
        } else {
          days = 7 - d.getDay() + 2;
        }
        return new Date(d.setDate(d.getDate() + days));
      },
    }
};

</script>


<style scoped>

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-card-body {
  text-align: left;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

li {
  list-style: none;
}

</style>